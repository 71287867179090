import copy from 'data/copy';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { NavLi, Sub, Wrapper } from './Nav.styles';

const Nav = (props) => {
  //PROPS
  const { lang, setMenuOpen } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query Menu {
      allWpPage {
        nodes {
          menu {
            menuItem {
              tipo
              link {
                url
                title
              }
              sublink {
                ... on WpPage {
                  title
                  seo {
                    slug
                  }
                }
              }
            }
          }
          language {
            code
          }
        }
      }
      allWpCategoriaProd {
        edges {
          node {
            name
            slug
            description
            language {
              code
            }
            count
            category {
              order
            }
            parentId
            wpChildren {
              nodes {
                slug
                name
                count
                description
              }
            }
          }
        }
      }
    }
  `);

  const {
    allWpPage: { nodes },
    allWpCategoriaProd: { edges: categoriesNodes },
  } = data;

  console.log(data);

  const categories = categoriesNodes
    .filter((e) => {
      return !e.node.parentId;
    })
    .filter((e) => e.node.language.code === lang)
    .sort((a, b) => a.node.category?.order - b.node.category?.order);

  const info = nodes
    .filter((item) => {
      if (lang) {
        return item.language.code === lang;
      }
      return null;
    })
    .filter((item) => item?.menu?.menuItem);

  const menu = info[0]?.menu.menuItem;
  //DATA

  const [imageSrc, setImageSrc] = useState(undefined);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const width = document.documentElement.clientWidth;

      const paddingLeft = document
        .querySelector('.nav-column')
        ?.getBoundingClientRect().left;

      if (document?.querySelector('.sub')) {
        document.querySelector('.sub').style.paddingLeft =
          paddingLeft - 24 + 'px';
      }

      document?.querySelectorAll('.sub__inner')?.forEach((e) => {
        var distanceLeft = window.pageYOffset + e.getBoundingClientRect().left;

        // console.log(distanceLeft, width, width * 0.5);
        if (distanceLeft >= width * 0.5) {
          e.classList.add('sub__inner--right');
        } else {
          e.classList.remove('sub__inner--right');
        }
      });
    }
  }, []);

  return (
    <Wrapper className="navContainer">
      {lang && (
        <>
          <ul className="menu">
            {menu &&
              menu.map((item, index) => {
                return (
                  <NavLi className="nav-column" key={index}>
                    <Link to={item.link.url} onClick={() => setMenuOpen(false)}>
                      {item.link.title}
                    </Link>
                    {item.sublink?.length && (
                      <ul>
                        {item.sublink?.map((subitem) => {
                          return (
                            <li key={subitem}>
                              <Link
                                to={subitem.seo.slug}
                                onClick={() => setMenuOpen(false)}
                              >
                                {subitem.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </NavLi>
                );
              })}
          </ul>
          <Sub className="sub">
            <ul>
              {!!categories?.length &&
                categories
                  .filter((e) => {
                    return true;
                    // return e?.node?.count;
                  })
                  .map((category, index) => {
                    let link = `/${copy.query.products[lang]}?${copy.query.category[lang]}=${category.node.slug}`;

                    category.node.wpChildren?.nodes.forEach((subcategory) => {
                      link += `&${copy.query.subcategory[lang]}=${subcategory.slug}`;
                    });

                    return (
                      <li key={index}>
                        <Link
                          to={link}
                          onClick={() => setMenuOpen(false)}
                        >
                          {category.node.name}
                        </Link>
                        {!!category.node.wpChildren?.nodes?.length && (
                          <div className="sub__inner">
                            <ul>
                              {category.node.wpChildren.nodes.map(
                                (subcategory, index) => {
                                  console.log(subcategory);
                                  return (
                                    <li
                                      key={index}
                                      onMouseEnter={() =>
                                        setImageSrc(subcategory.description)
                                      }
                                      onMouseLeave={() =>
                                        setImageSrc(undefined)
                                      }
                                    >
                                      <Link
                                        to={`/${copy.query.products[lang]}?${copy.query.category[lang]}=${category.node.slug}&${copy.query.subcategory[lang]}=${subcategory.slug}`}
                                        onClick={() => setMenuOpen(false)}
                                      >
                                        {subcategory.name}
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                            <div className="sub__image">
                              <img
                                src={imageSrc ?? category.node.description}
                              />
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
              <li>
                <Link to={lang === "PT"? '/enchimentos-para-estofos' : '/upholstery-fillings'}>
                  {copy.fillingsSofa[lang]}
                </Link>
              </li>
              <li>
                <Link to={'/' + copy.query.products[lang]}>
                  {copy.all[lang]}
                </Link>
              </li>
            </ul>
          </Sub>
        </>
      )}
    </Wrapper>
  );
};

export default Nav;
