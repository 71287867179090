import React from 'react';

import Banner from 'components/banner';
import BannerImages from 'components/banner-images';
import VideoBanner from 'components/videobanner';
import TextBlock from 'components/textBlock';
import Thumbnails from 'components/thumbnails';
import Relations from 'components/relations';
import AnimationWrapper from 'components/animation-wrapper';

function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

const DynaContent = (props) => {
  //PROPS
  const { rows, setExtraInfo, lang } = props;
  //PROPS

  if (!!rows) {
    return rows
      .filter((o) => o !== null)
      .map((e, index) => {
        const type = capitalizeFirstLetter(Object.keys(e)[0]);
        const TextBlockAnim = (props) => {
          return (
            <AnimationWrapper>
              <TextBlock {...props} />
            </AnimationWrapper>
          );
        };
        const Text = TextBlockAnim;
        const components = {
          Banner,
          Relations,
          BannerImages,
          Thumbnail: Thumbnails,
          VideoBanner,
          Text,
        };
        const Component = components[type];
        return (
          Component && (
            <Component
              key={index}
              data={e}
              lang={lang}
              setExtraInfo={setExtraInfo}
            />
          )
        );
      });
  } else {
    return null;
  }
};

export default DynaContent;
