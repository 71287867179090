import { useEffect, useState } from 'react';
import axios from 'axios';

const _checkParams = (search) => {
  const params = new URLSearchParams(search);

  const pagination = Number(params.has('pagina') ? params.get('pagina') : 1);
  const categories = params.has('categoria') ? params.getAll('categoria') : [];
  const subcategories = params.has('subcategoria')
    ? params.getAll('subcategoria')
    : [];

  return { categories, subcategories, pagination };
};

const _fetchData = (query, variables) => {
  let categoriesInfo = `{}`;
  if (variables.categories?.length) {
    categoriesInfo = `{taxonomy: CATEGORIAPROD, operator: IN, field: SLUG, terms: ${JSON.stringify(
      variables.categories
    )} }`;
  }
  let newQuery = query.replace(':queries', categoriesInfo);

  return axios.post(process.env.GATSBY_WP, {
    query: newQuery,
    variables,
  });
};

const useFilters = (props) => {
  const search = props?.location?.search;

  //STATES
  const [filters, setFilters] = useState();
  const [showFilters, setShowFilters] = useState(false);
  //STATES

  useEffect(() => {
    const { categories, subcategories, pagination } = _checkParams(search);
    setFilters({ categories, subcategories, pagination });
  }, [search]);

  return {
    filters,
    showFilters,
    toggleFilters: setShowFilters,
  };
};

const productsQuery = `
  query getProducts($lang: LanguageCodeFilterEnum!, $offset: Int!) {
    produtos(where: { language: $lang, offsetPagination: {offset: $offset, size: 9}, taxQuery: {taxArray: [:queries]}}) {
      pageInfo {
        offsetPagination {
          total
        }
      }
      nodes {
        title
        seo {
          slug
        }
        product {
          imagem {
            altText
            srcSet(size: MEDIUM)
          }
        }
      }
    }
  }
`;

const productsQueryAll = `
  query getProducts($lang: LanguageCodeFilterEnum!, $offset: Int!) {
    produtos(where: { language: $lang, offsetPagination: {offset: $offset, size: 9}}) {
      pageInfo {
        offsetPagination {
          total
        }
      }
      nodes {
        title
        seo {
          slug
        }
        product {
          imagem {
            altText
            srcSet(size: MEDIUM)
          }
        }
      }
    }
  }
`;

const useProducts = (props) => {
  const { filters, showFilters, lang } = props;

  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (filters && !showFilters && typeof window !== 'undefined') {
      const fetchData = async () => {
        try {
          const categories = filters.subcategories.length ? [...filters.subcategories] : [...filters.categories, ...filters.subcategories];
          setIsLoading(true);
          const response = await _fetchData(
            categories.length ? productsQuery : productsQueryAll,
            {
              lang,
              offset: (filters.pagination - 1 ?? 0) * 9,
              categories,
            }
          );
          const responseData = response.data.data.produtos;
          setItems(responseData.nodes || []);
          setTotalItems(responseData.pageInfo.offsetPagination.total);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [
    filters?.categories,
    filters?.subcategories,
    filters?.pagination,
    showFilters,
  ]);

  return {
    items,
    totalItems,
    isLoading,
  };
};

export { useFilters, useProducts };
