import React from 'react';

import { Link } from 'gatsby';

import { Wrapper, Lang } from './SecondNav.styles';

const SecondNav = (props) => {
  //PROPS
  const { lang, pageTranslation, setMenuOpen } = props;
  //PROPS

  return (
    <Wrapper className="secondNav">
      <Lang>
        <li className={lang === 'PT' ? 'active' : ''}>
          {lang === 'PT' ? (
            <span>PT</span>
          ) : (
            <Link to={pageTranslation} onClick={() => setMenuOpen(false)}>
              PT
            </Link>
          )}
        </li>
        <li className={lang === 'EN' ? 'active' : ''}>
          {lang === 'EN' ? (
            <span>EN</span>
          ) : (
            <Link to={pageTranslation} onClick={() => setMenuOpen(false)}>
              EN
            </Link>
          )}
        </li>
      </Lang>
    </Wrapper>
  );
};

export default SecondNav;
